.top-to-btm {
  position: relative;
}
.icon-position {
  display: none;
}
.icon-style {
  height: 50px;
  width: 50px;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
}
